import Home from './Pages/HomePage/Home';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import UserLogin from './Pages/LoginPages/UserLogin';
import AdminLogin from './Pages/LoginPages/AdminLogin';
import Profile from './Pages/ProfilePages/Profile';
import NotFound from './Pages/NotfoundPage/NotFound';
import InProgres from './Pages/InfoPages/InProgres';
import InVerif from './Pages/InfoPages/InVerif';
import RecentUsersDB from './Pages/DashboardPages/RecentUsersDB';
import RecentUsersDBid from './Pages/DashboardPages/RecentUsersDBid';
import ClassGroupingDB from './Pages/DashboardPages/ClassGroupingDB';
import ListClassDB from './Pages/DashboardPages/ListClass';
import RejectUser from './Pages/InfoPages/RejectUser';
import InputScore from './Pages/DashboardPages/InputScore';
import AcceptUsersDB from './Pages/DashboardPages/AcceptUsersDB';
import AcceptUsersDBid from './Pages/DashboardPages/AcceptUsersDBid';



function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />;
        <Route path="userlogin" element={<UserLogin />} />
        <Route path="adminlogin" element={<AdminLogin />} />
        <Route path="adminlogin/recentusersdb" element={<RecentUsersDB />} />
        <Route path="adminlogin/recentusersdb/:id" element={<RecentUsersDBid />} />
        <Route path="adminlogin/acceptcontact" element={<AcceptUsersDB />} />
        <Route path="adminlogin/acceptusersdb/:id" element={<AcceptUsersDBid />} />
        <Route path="adminlogin/listclassdb" element={<ListClassDB />} />
        <Route path="adminlogin/classgroupingdb/:id" element={<ClassGroupingDB />} />
        <Route path="adminlogin/inputscore" element={<InputScore />} />
        <Route path="userlogin/detailprofile/:nim" element={<Profile />} />
        <Route path="userlogin/inprogres/:nim" element={<InProgres />} />
        <Route path="userlogin/inverif" element={<InVerif />} />
        <Route path="userlogin/rejectuser" element={<RejectUser />} />
        <Route path="*" element={<NotFound />} />
      </Routes> 
    </div>
  );
}

export default App;
