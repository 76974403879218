import "./RejectUser.css"

export default function RejectUser() {
    return(
    <div className="body">
        <section className='bg-InProgres'>
        <div className='container-InProgres'>
          <div className='row-InProgres'>
            <h1>Anda tidak dapat mengikuti TOEP</h1>
            <p><i>Data Anda gagal diverifikasi</i></p>
          </div>
        </div>
      </section>
      <div className='margin-InProgres'></div>
    </div>);
}