import "./InputScore.css";
import MenuBar from "./MenuBar";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";

export default function InputScore() {
  const [rows, setRows] = useState([
    { id: "KRISNA PUTRA NUR QODRI", period: "GENAP 2024", m1: 0, m2: 0, m3: 0, score: 0 },
    { id: "FEBRI", period: "GENAP 2024", m1: 0, m2: 0, m3: 0, score: 0 },
    { id: "FAAZA", period: "GENAP 2024", m1: 0, m2: 0, m3: 0, score: 0 },
   
  ]);

 

  const handleChange = (event, params) => {
    const { name, value } = event.target;
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === params.row.id
          ? { ...row, [name]: Number(value) }
          : row
      )
    );
  };


  const handleAddRow = (newData) => {
    setRows([...rows, newData]);
  };

  const actionColumns = [
    {
      field: "inputscore1",
      headerName: "Listening",
      width: 120,
      renderCell: (params) => (
        <div className="inputscoredb4">
          <input
            type="Number"
            name="m1"
            value={params.row.m1}
            onChange={(event) => handleChange(event, params)}
          />
        </div>
      ),
    },
    {
      field: "inputscore2",
      headerName: "Structure",
      width: 120,
      renderCell: (params) => (
        <div className="inputscoredb4">
          <input
            type="Number"
            name="m2"
            value={params.row.m2}
            onChange={(event) => handleChange(event, params)}
          />
        </div>
      ),
    },
    {
      field: "inputscore3",
      headerName: "Reading",
      width: 120,
      renderCell: (params) => (
        <div className="inputscoredb4">
          <input
            type="Number"
            name="m3"
            value={params.row.m3}
            onChange={(event) => handleChange(event, params)}
          />
        </div>
      ),
    },
    {
      field: "score",
      headerName: "Score",
      width: 120,
      renderCell: (params) => (
        <div className="inputscoredb4">
          <input
            type="Number"
            name="score"
            value={params.row.score}
            onChange={(event) => handleChange(event, params)}
          />
        </div>
      ),
    },
  ];

  const columns = [{ field: "id", headerName: "Nama Mahasiswa", width: 320 },
                    { field: "period", headerName: "Periode", width: 250, },
                  ];

  return (
    <div className="body">
      <div className="judulDB3">
        <b>| <i>Input Score</i></b>
      </div>
      <button className="savedb4" onClick={() => handleAddRow({ id: "Data Baru" })}>SIMPAN</button>
      <div className="datatableClassGroupingDB3">
        <DataGrid
          rows={rows}
          columns={columns.concat(actionColumns)}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 8 },
            },
          }}
          pageSizeOptions={[8]}
        />
      </div>
      <MenuBar />
    </div>
  );
}