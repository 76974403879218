import "./InProgres.css"
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function InProgres() {
  const [rows, setRows] = useState([]);
  const params = useParams();
  const apiUrl = process.env.REACT_APP_API_URL_TOEP;


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}/contact`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': localStorage.getItem('token'),
        },
        withCredentials :false,
      });

      if(!response.ok){
        throw new Error('failed to fetch data');
      }

      const data = await response.json();
      setRows(data[0]);
    } catch (error) {
      console.error('Error fetching data :', error)
    }
  };


    return(
    <div className="body">
        <section className='bg-InProgres'>
        <div className='container-InProgres'>
          <div className='row-InProgres'>
            <h1>Data Anda Sedang Diproses</h1>
            <p><i>Mohon tunggu untuk diverifikasi</i></p>
            <div className="container-btndownload">
            <Link to={`${apiUrl}/mhs/pdf/${params.nim}`} target="_blank"><button type="button" className="btn-download"><b>Unduh Bukti Pendaftaran</b></button></Link>
          </div>
          </div>
        </div>
      </section>
      <div className='margin-InProgres'></div>
    </div>);
}