import "./MenuBar.css"
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function MenuBar() {
  const apiUrl = process.env.REACT_APP_API_URL_TOEP;

  const usenavigate = useNavigate();
  
  const handleLogout = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post(`${apiUrl}/logout`, {}, {
        headers: {
          'Accept': 'application/json',  
  
          'Authorization': localStorage.getItem('token'), 
        },
        withCredentials: false,
      });
  
      if (response.data) { 
        localStorage.removeItem('token'); 
        usenavigate('/'); 
        console.log("Logout successful");
        localStorage.clear();
      }
    } catch (error) {
      console.error("Logout error:", error); 
    }
  };
  
    return(
    <div className="body">
       <nav className='nav-db'>
        <ul>
          <li className="judul-db">
            <img src='/UNIKAMA.png' alt='UNIKAMA' className="logoDashboard"/>
            <span className='span-db'><b>TOEP UNIKAMA</b></span>
          </li>
        </ul>
        <ul className="item-db">  
          <li className="li-db"><Link to='/adminlogin/recentusersdb' className="link-db"><img src='/user.png' alt='UNIKAMA' className="itemDashboard"/><span className='span-db'><i className="txt-db">Detail Pendaftar</i></span></Link></li>
          <li className="li-db"><Link to='/adminlogin/listclassdb' className="link-db"><img src='/listclass.png' alt='UNIKAMA' className="itemDashboard"/><span className='span-db'><i className="txt-db">Periode Pendaftaran</i></span></Link></li>
          <li className="li-db"><Link to='/adminlogin/acceptcontact' className="link-db"><img src='/whatsappicon.png' alt='UNIKAMA' className="itemDashboard"/><span className='span-db'><i className="txt-db">Ubah Contact</i></span></Link></li>
          <li className="li-db"><span className='span-db'><i className="txt-db"><button className="buttonLogout"  onClick={handleLogout}>Logout</button></i></span></li>
        </ul>
       </nav>
    </div>);
}