import "./NotFound.css"

export default function NotFound() {
    return(
    <div className="body">
        <section className='bg-404'>
        <div className='container-404'>
          <div className='row-404'>
            <h1>404</h1>
            <p><i>Oops! Page Not Found</i></p>
          </div>
        </div>
      </section>
      <div className='margin-404'></div>
    </div>);
}