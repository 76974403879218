import "./UserLogin.css";
import HeaderLog from "./HeaderLog";
import ReCAPTCHA from "react-google-recaptcha";
import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom"

export default function UserLogin() {
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [nim, setNim] = useState("");
  const userRef = useRef();
  const errRef = useRef();
  const usenavigate=useNavigate();
  const [errMsg, setErrMsg] = useState('');
  const apiUrlToep = process.env.REACT_APP_API_URL_TOEP;
  const apiUrl = process.env.REACT_APP_API_URL_SIAKAD;

  console.log(`${apiUrl}`)

  useEffect(() => {
    userRef.current.focus();
}, [])

useEffect(() => {
    setErrMsg('');
}, [nim])

const authenticate = async (e) => {
  e.preventDefault();

  try {
    const response1 = await fetch(`${apiUrlToep}/mhs/info/${nim}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    if (!response1.ok) {
      throw new Error("Error fetching student data");
    }

    const data1 = await response1.json();

    if (data1.message === "Success") {
      let redirectPath;
      switch (data1.data.status) {
        case "proses":
          redirectPath = `/userlogin/inprogres/${nim}`;
          break;
        case "tertolak":
          redirectPath = '/userlogin/rejectuser';
          break;
        case "terdaftar":
          redirectPath = '/userlogin/inverif';
          break;
        default:
          break;
      }

      if (redirectPath) {
        usenavigate(redirectPath);
        return; 
      }
    } else {
    }

    const response2 = await fetch(`${apiUrl}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        email: "toepaccess@gmail.com",
        password: "toep-access-123",
      }),
    });

    if (!response2.ok) {
      throw new Error("Login failed!");
    }

    const data2 = await response2.json();
    const token = data2.token;

    if (token) {
      const arrayToken = Object.values(token);
      localStorage.setItem("token-siakad", "Bearer " + arrayToken);
      localStorage.setItem("loggedIn", true);
      usenavigate(`/userlogin/detailprofile/${nim}`);
    }

  } catch (err) {
    if (!err?.response) {
      setErrMsg('Login Failed');
      console.error(err)
    } else if (err.response?.status === 400) {
      setErrMsg('Login Failed');
    } else if (err.response?.status === 401) {
      setErrMsg('Unauthorized');
    } else {
      setErrMsg('Login Failed');
    }
    errRef.current.focus();
  }
};

  return (
    <div className="body">
      <HeaderLog />
      <section className="bg-userLogin">
        <div className="containerUserLog">
        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
          <form className="formuser" onSubmit={authenticate}>
            <h1>STUDENT LOGIN</h1>
            <div className="garisuser"></div>

            <div className="container-User">
              <label>
                <b>NIM </b>
              </label>
              <input
                ref={userRef}
                type="text"
                inputMode="numeric"
                placeholder="NIM"
                name="nim"
                value={nim}
                onChange={(e) => setNim(e.target.value)}
                required
              />

              <div className="chaptcha">
                <ReCAPTCHA
                  sitekey="6LeDkngqAAAAAB_ZXWeRwx3OHnkVC1ATECg_pbd3"
                  onChange={(value) => {
                    setRecaptchaToken(value);
                  }}
                />
              </div>

              <div className="remember">
                <input type="checkbox" name="remember" /> Remember me
              </div>
              <div>
                  <button
                    className="buttonLogin"
                    disabled={!recaptchaToken}
                  >
                    Login
                  </button>
                  <p className="txtOpsiLogin"> <Link to='/userlogin' className="loginUser"><b>User Login</b></Link> | <Link to='/adminlogin' className="loginAdmin"><b>Admin Login</b></Link></p>
              </div>
            </div>
          </form>
        </div>
      </section>
      <div className="margin"></div>
    </div>
  );
}
