import "./header.css"
import { useState } from "react"
import { Link } from "react-router-dom"

export default function Header() {
  const [click, setClick] = useState(false)
  return (
    <div className="body-bg">
      <header>
        <nav className='flexSB'>
          <ul className={click ? "mobile-nav" : "flexSB "} onClick={() => setClick(false)}>
              <li><img src='../UNIKAMA.png' alt='UNIKAMA' className="logo1"/></li>
          </ul>   
          <div className='start'>
            <div className='login'><Link to='/userlogin'><b>LOGIN</b></Link></div>
          </div>
        </nav>
      </header>
    </div>
  )
}
