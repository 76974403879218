import "./HeaderLog.css"
import { useState } from "react"

export default function HeaderLog() {
  const [click, setClick] = useState(false)
  return (
    <div className="body-bg">
      <header className='headerLog'>
        <nav className='flexSB'>
          <ul className={click ? "mobile-navLog" : "flexSB "} onClick={() => setClick(false)}>
              <li><img src='../UNIKAMA.png' alt='UNIKAMA' className="logo1Log"/></li>
          </ul>   
          <div className='startLog'>
          </div>
        </nav>
      </header>
    </div>
  )
}