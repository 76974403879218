import "./AdminLogin.css"
import HeaderLog from "./HeaderLog"
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { useRef, useState, useEffect, useContext } from 'react';
import AuthContext from "./AuthProvider";
import { Navigate } from "react-router-dom";
import { setAuthenticationHeader } from "./authenticate";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom"

export default function AdminLogin() {
  const { setAuth } = useContext(AuthContext);
  const userRef = useRef();
  const errRef = useRef();
  const apiUrl = process.env.REACT_APP_API_URL_TOEP;
  const usenavigate=useNavigate();

  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    userRef.current.focus();
}, [])

useEffect(() => {
    setErrMsg('');
}, [email, password])

const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);

    try {
        const response = await axios.post(`${apiUrl}/login`, formData,
            JSON.stringify({ email, password }),
            {
                withCredentials: false
            }
        ).then(response => {
            if(response.data) {     
              const token = response.data.token
              const arrayToken = Object.values(token);
              localStorage.setItem('token', 'Bearer ' +  arrayToken);
              axios.defaults.headers.common['Authorization'] = 'Bearer ' +  arrayToken;
              localStorage.setItem("loggedIn", true);
              setAuthenticationHeader(token); 
              usenavigate('recentusersdb');
            }
          }).catch(error => {
            console.error(error)    
          })
      
        const accessToken = response?.data?.accessToken;
        const roles = response?.data?.roles;
        setAuth({ email, password, roles, accessToken });
        setEmail('');
        setPassword('');
        setSuccess(true);
    } catch (err) {
        if (!err?.response) {
            setErrMsg('Wrong Email or Password');
        } else if (err.response?.status === 400) {
            setErrMsg('Missing Username or Password');
        } else if (err.response?.status === 401) {
            setErrMsg('Unauthorized');
        } else {
            setErrMsg('Login Failed');
        }
        errRef.current.focus();
    }
    if (recaptchaToken) {
      formData.append('recaptcha', recaptchaToken);
  
    } else {
      setErrMsg("Harap selesaikan reCAPTCHA terlebih dahulu.");
    }
};



  return(
    <div className="body">
        <HeaderLog />
        {success ? (
                <Navigate to="recentusersdb"/>
            ) : (
        <section className='bg-AdminLogin'>
        <div className='containerAdminLog'>
        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
    <form className='formAdm' onSubmit={handleSubmit}>
        <h1>ADMIN LOGIN</h1>
          <div className="garisAdm"></div>

        <div className="container-Adm">
          <label  htmlFor="email"><b>Username or Email Address </b></label>
            <input 
                type="text" placeholder="Username or Email Address" name="email" id="email"  ref={userRef}
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                value={email} required />

            <label  htmlFor="password"><b>Password  </b></label>
            <input 
              type="password" placeholder="Password" name="password" id="password" 
              onChange={(e) => setPassword(e.target.value)}
              value={password} required /> 

            <div className="chaptcha">
            <ReCAPTCHA
            sitekey="6LeDkngqAAAAAB_ZXWeRwx3OHnkVC1ATECg_pbd3" onChange={(value) => {
              setRecaptchaToken(value);
            }}
            /></div>
            
            <div className="remember"><input type="checkbox" name="remember" /> Remember me </div>
            <div><button className="buttonLogin" disabled={!recaptchaToken}>Login</button></div>
            <p className="txtOpsiLogin"> <Link to='/userlogin' className="loginUser"><b>User Login</b></Link> | <Link to='/adminlogin' className="loginAdmin"><b>Admin Login</b></Link></p>
          </div>
        </form> 
        </div>
        </section>
      )} 
      <div className='margin'></div> 
    </div>
    ); 
}
